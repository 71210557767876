
  li {
    list-style-type: none;  /*옆에 점찍히거나 숫자찍히는거 옵션*/
  }

  /* ul: 순서가 필요없는 목록 */
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .ais-Pagination {
    width: 100%;
  }

  /* 페이지네이션 위치 */
  .ais-Pagination-list {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:60px;
  }

  /* 페이지네이션 간격 */
  .ais-Pagination-item {
    color: #ADB5BD;
    padding: 6px 12px;
    font-size: 14px;
    transition: background-color .3s;
    font-weight: 500;
    display: block;
  }

  .ais-Hits {
    display: flex;
    width: 100%;
  }

  /* hit된 애들 사이즈 조절 */
  .ais-Hits-item {
    width: 33%;
  }

  @media only screen and (max-width: 880px){
    .ais-Hits-item {
      width: 100%;
    }
  }

  .ais-Hits-list {
    display: flex;
    justify-content: space-between;
  }
